








































import { Component, Vue, Prop } from 'vue-property-decorator';
import BpI18nHandlerMixin from '../mixins/bp-i18n-handler-mixin';

@Component({
    name: 'c-spendings-modal',
})

export default class CDatePicker extends BpI18nHandlerMixin {
    @Prop({
        required: true,
    })
    private header!: any;

    private mounted(): void {
    }

    protected spndingsRecs = {};
    private showModal(items: any[]): void {
        this.resetForm();
        this.spndingsRecs = this.prepareData(items);
        this.$bvModal.show('spendings-modal');
    }

    protected prepareData(items: any[]): any {
        const treeItem: {
            [key: number]: {
                [key: number]: any[]
            }
        } = {};
        
        items.sort((a: any, b: any) => {
                if (a.cur_year !== b.cur_year) {
                    return a.cur_year - b.cur_year;
                }
                if (a.ppr !== b.ppr) {
                    return a.ppr - b.ppr;
                }
                if (a.year !== b.year) {
                    return a.year - b.year;
                }
                return a.data_type - b.data_type;
            })
            .forEach((itm: any) => {
                const curYear = itm.cur_year;
                const curPpr = itm.ppr;
                if (curYear in treeItem) {
                    if (curPpr in treeItem[curYear]) {
                        treeItem[curYear][curPpr].push(itm);
                    } else {
                        treeItem[curYear][curPpr] = [itm];
                    }
                } else {
                    treeItem[curYear] = {};
                    treeItem[curYear][curPpr] = [itm];
                }
            });
        return treeItem;
    }

    protected closeModal(): void {
        this.$bvModal.hide('spendings-modal');
    } // закрытие модалки
    
    protected showFormsList: number[] = [];
    protected toggleIndex(index: number): void {
        const indexInArray = this.showFormsList.findIndex((itm: number) => itm === index);
        if (indexInArray === -1) {
            this.showFormsList.push(index);
        } else {
            this.showFormsList.splice(indexInArray, 1);
        }
    }

    protected isShouldBeShown(index: number): boolean {
        return this.showFormsList.includes(index);
    } // управляет отображением элементов

    protected openForm(data: any): void {
        const fromCode = this.header.form.replace('_', '-')
        const params = `#/form${fromCode}/${data.cur_year}/${data.year}/${data.data_type}/${data.gr}/${data.abp}/${data.prg}/${data.ppr}/${this.header.gu}/${data.variant}/${data.spf}/${this.header.gkkp}/${this.header.mode}/true/end`;
        document.location.assign(params);
        this.$bvModal.hide('spendings-modal');

    }

    protected resetForm(): void {
        this.spndingsRecs = {};
        this.showFormsList.splice(0);
    }

    protected get getFormText(): Function {
        return this.setFormText('form_02_159_1.');
    }
}

